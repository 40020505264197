* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

.wrapperImage{
  max-width: 70rem;
  margin: 3rem auto;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: 300px;
  /* cursor: pointer; */
}
.loading{
text-align: center;}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #222;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.header{
  max-width: 70rem;
  margin: 2rem auto;
  text-align: center;
}
.imgcls{
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}
button{
  border: none;
}
.leftIcon{
  margin-top: 250px;
}
.rightIcon{
  margin-top: 250px;
}
.lc{
  font-size: 40px;
  background: #ffff;
  border: none;
}
.rc{
    font-size: 40px;
    background: #ffff;
    border: none;
}
.iconDiv{
  display: flex;
  justify-content: center;
}
.insta{
 font-size: 40px;
 margin-left: 10px;
 background:rgb(15, 15, 15);
 cursor: pointer;
 color: rgb(136, 207, 212);
 opacity: 0.5;
}
.model{
  width: 100%;
  height: 100vh;
  position: fixed;
  top:0;
  left:0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000000;
  transition:opacity .4s ease, visibility .4s ease, transform .5s ease-in-out ;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}
.model.open{

  visibility: visible;
  opacity: 1;
  transform: scale(1);

}
 img{
  width: auto;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin:  0 auto;
}
.model img{
  width: auto;
  max-height: 90%;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin:  0 auto;
}
.model.open .closesvg{
  position: fixed;
  top: 10px;
  right: 10px;
  width: 2rem;
  height: 2rem;
  padding: 5px;
  background-color: rgba(0,0,0,0.4);
  color: #ffffff;
  cursor: pointer;
}
.model.open .leftsvg{
  position: fixed;
  top: 50%;
  left: 10px;
  width: 2rem;
  height: 2rem;
  padding: 5px;
  background-color: rgba(0,0,0,0.4);
  color: #ffffff;
  cursor: pointer;
}
.model.open .rightsvg{
  position: fixed;
  top: 50%;
  right: 10px;
  width: 2rem;
  height: 2rem;
  padding: 5px;
  background-color: rgba(0,0,0,0.4);
  color: #ffffff;
  cursor: pointer;
}
.model.open .iconDiv{
  position: fixed;
  bottom: 10px;
  left: 10%;
  width: 2rem;
  height: 2rem;
  padding: 5px;
  background-color: rgba(0,0,0,0.4);
  color: #ffffff;
  cursor: pointer;
  margin-bottom: 10px;
}
.user_name{
  position: fixed;
  bottom: 2px;
  left: 0%;
  width: 100%;
  height: 2rem;
  /* background-color: rgba(0,0,0,0.4); */
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .model.open .iconDiv{
    position: fixed;
    bottom: 10px;
    left: 43%;
    width: 2rem;
    height: 2rem;
    padding: 5px;
    background-color: rgba(0,0,0,0.4);
    color: #ffffff;
    cursor: pointer;
    margin-bottom: 10px;
  }
  .user_name{
    position: fixed;
    bottom: 7%;
    left: 0%;
    width: 100%;
    height: 2rem;
    /* background-color: rgba(0,0,0,0.4); */
    color: #ffffff;
    cursor: pointer;
    text-align: center;
  }
}